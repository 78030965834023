import logo from "../assets/Brickous Logo White-Orange.png";
import "../styles/home.css";
import { FaArrowRight } from "react-icons/fa";

const Home = () => {
  return (
    <div className="home-container">
      <div className="bg"></div>
      <div className="logo-container">
        <img className="logo" src={logo} alt="" />
      </div>

      <div className="text-container">
        <p className="mb-4 text-sm tracking-wider text-white">
          CHOICE AROUND THE WORLD
        </p>
        <div className="h2remseparator" />

        <h1 className="mb-8 text-5xl font-bold tracking-tight sm:text-7xl text-white">
          Investing for the
        </h1>
        <h1 className=" mb-8 text-5xl font-bold tracking-tight sm:text-7xl text-primary">
          Real Estate Revolution
        </h1>

        <div className="h2remseparator" />

        <a href="https://app.brickous.com" target="_blank">
          <button className="solid-border-btn">
            Invest <FaArrowRight size={15} className="solid-border-btn-icon" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default Home;
